// routes
import ROUTES_PATH from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  aboutUs: getIcon('ic_about_us'),
  contactUs: getIcon('ic_contact_us'),
};

const navConfig = [
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: ROUTES_PATH.dashboard.root,
        icon: ICONS.dashboard,
      },
    ],
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'company',
        path: ROUTES_PATH.company.root,
        icon: ICONS.user,
        children: [
          { title: 'Company Details', path: ROUTES_PATH.company.details },
          { title: 'Company Address', path: ROUTES_PATH.company.address },
          { title: 'Settings', path: ROUTES_PATH.company.settings },
        ],
      },
      // {
      //   title: 'manage my website',
      //   path: ROUTES_PATH.root,
      //   icon: ICONS.blog,
      //   children: [
      //     {
      //       title: 'package',
      //       path: ROUTES_PATH.package.root,
      //       children: [
      //         { title: 'list', path: ROUTES_PATH.package.list },
      //         { title: 'create', path: ROUTES_PATH.package.create },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   title: 'package',
      //   path: ROUTES_PATH.package.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'list', path: ROUTES_PATH.package.list },
      //     { title: 'create', path: ROUTES_PATH.package.create },
      //   ],
      // },
      {
        title: 'quotation',
        path: ROUTES_PATH.quotation.root,
        icon: ICONS.invoice,
        children: [
          { title: 'list', path: ROUTES_PATH.quotation.list },
          { title: 'create', path: ROUTES_PATH.quotation.create },
        ],
      },
      {
        title: 'my team',
        path: ROUTES_PATH.myTeam.root,
        icon: ICONS.user,
        children: [
          { title: 'Manage Members', path: ROUTES_PATH.myTeam.list },
          { title: 'Manage Team Code', path: ROUTES_PATH.myTeam.manageCode },
        ],
      },
      {
        title: 'customer',
        path: ROUTES_PATH.customer.root,
        icon: ICONS.mail,
        children: [{ title: 'My Customers', path: ROUTES_PATH.customer.list }],
      },
      {
        title: 'subscriptions',
        path: ROUTES_PATH.plan.root,
        icon: ICONS.cart,
        children: [
          { title: 'All Plans', path: ROUTES_PATH.plan.list },
          { title: 'History', path: ROUTES_PATH.plan.history },
        ],
      },
    ],
  },
  {
    subheader: 'manage my website',
    items: [
      {
        title: 'package',
        path: ROUTES_PATH.package.root,
        children: [
          { title: 'list', path: ROUTES_PATH.package.list },
          { title: 'create', path: ROUTES_PATH.package.create },
        ],
      },
    ],
  },
  {
    subheader: 'settings',
    items: [
      {
        title: 'Contact Us',
        path: ROUTES_PATH.dashboard.contact,
        icon: ICONS.contactUs,
      },
    ],
  },
  // {
  //   subheader: 'other',
  //   items: [
  //     {
  //       title: 'about us',
  //       path: ROUTES_PATH.dashboard.about,
  //       icon: ICONS.aboutUs,
  //     },
  //     {
  //       title: 'contact us',
  //       path: ROUTES_PATH.dashboard.contact,
  //       icon: ICONS.contactUs,
  //     },
  //   ],
  // },
];

export default navConfig;
