import { useSelector } from 'react-redux';
// ** MUI Imports
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ProhibitedForTeamMemberAlert = () => {
  // ** Redux States
  const { userInfo } = useSelector((state) => state.authReducer);

  const isHide = false;

  return userInfo && userInfo?.userDetails?.userType === 'team_member' ? (
    <>
      <Alert severity="info" sx={{ mb: 3 }}>
        <AlertTitle>Access Restricted</AlertTitle>
        <Stack spacing={2}>
          <Typography>Access restricted to administrators. Team members cannot view this section.</Typography>
        </Stack>
      </Alert>
    </>
  ) : null;
};

export default ProhibitedForTeamMemberAlert;
