// ** React Imports
import { useEffect } from 'react';
// ** AWS Imports
import Amplify, { Auth } from 'aws-amplify';
// ** Redux Imports
import { useDispatch } from 'react-redux';
import { login, setProfileData, setShopData } from './redux/slices/auth';
// ** Router with 'useRoutes' to define pages
import { useNavigate } from 'react-router';
import Router from './routes';
// ** Theme provider
import ThemeProvider from './theme';
// ** Import progress bar, notify provider and scroll to top component
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
// ** Import 'utils', 'config' and 'env' variables
import { AWS_CONFIG } from './env';
// ** Imports GET, POST, PUT and DELETE utils fnc
import ROUTES_PATH from './routes/paths';
import {
  getShopDataByShopId,
  getShopDataByUserId,
  getShopDataForTeamMemberByUserId,
  getUserByEmail,
  handleUserSignIn,
} from './utils/auth';

export default function App() {
  const navigate = useNavigate();

  Amplify.configure({
    Auth: {
      mandatorySignId: true,
      region: AWS_CONFIG.cognito.REGION,
      userPoolId: AWS_CONFIG.cognito.USER_POOL_ID,
      userPoolWebClientId: AWS_CONFIG.cognito.APP_CLIENT_ID,
    },
  });

  const dispatch = useDispatch();

  const handleAutoLogin = async () => {
    try {
      const congitoUserInfo = await Auth.currentUserInfo();
      await handleUserSignIn({ email: congitoUserInfo?.attributes?.email }, dispatch, navigate);

      // ------ We merged after login function and validate auth on relaod function
      // const cognitoSession = await Auth.currentSession();
      // const congitoUserInfo = await Auth.currentUserInfo();
      // const accessToken = cognitoSession.getIdToken().getJwtToken();

      // // find user by access token
      // const userRes = await getUserByEmail(congitoUserInfo?.attributes?.email, accessToken);

      // if (!userRes) {
      //   throw new Error('Invalid token or User not found');
      // }

      // dispatch(login(accessToken));
      // dispatch(setProfileData(userRes));

      // // find shop using user id, if userType 'shop'
      // if (userRes.userDetails.userType === 'team_member') {
      //   const adminShopRes = await getShopDataForTeamMemberByUserId({
      //     userId: userRes?.userDetails?.userId,
      //     accessToken,
      //   });

      //   if (adminShopRes?.status === 'approved') {
      //     // Now call shop details using shopID then get shop admin user
      //     const actualShopRes = await getShopDataByShopId({ shopId: adminShopRes.shopId, accessToken });
      //     dispatch(setShopData(actualShopRes || null)); /** Set shop to Redux */

      //     const actualShopUserRes = await getUserByEmail(actualShopRes.shopEmail, accessToken);
      //     if (actualShopUserRes && actualShopUserRes.userDetails) {
      //       dispatch(setProfileData({ ...userRes, userSubscription: actualShopUserRes.userSubscription }));
      //     }
      //   } else {
      //     dispatch(setShopData(adminShopRes || null)); /** Set shop to Redux */
      //   }
      // } else {
      //   const shopRes = await getShopDataByUserId({
      //     userId: userRes?.userDetails?.userId,
      //     accessToken,
      //   });

      //   dispatch(setShopData(shopRes?.[0] || null)); /** Set shop to Redux */

      //   if (!shopRes?.[0] && !window.location.pathname.includes(ROUTES_PATH.auth.verify)) {
      //     navigate(ROUTES_PATH.company.details);
      //   }
      // }
    } catch (e) {
      navigate(ROUTES_PATH.dashboard.home);
    }
  };

  useEffect(() => {
    handleAutoLogin();
  }, []);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        {/* <ThemeSettings> */}
        <NotistackProvider>
          <ProgressBarStyle />
          <ChartStyle />
          <ScrollToTop />
          <Router />
        </NotistackProvider>
        {/* </ThemeSettings> */}
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
