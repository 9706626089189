// ** AWS Imports
import { Auth } from 'aws-amplify';
// ** Axios Import
import axios from 'axios';
// ** Redux functions
import { login, setProfileData, setShopAddress, setShopData } from '../redux/slices/auth';
// ** Import 'env' and GET, POST, PUT and DELETE methods
import { AWS_API_ENDPOINTS } from 'src/env';
import { env } from '../config/env/release';
import { getData, postData } from './requests';
import ROUTES_PATH from 'src/routes/paths';

const { COMMON_ENDPOINTS, SHOP_ENDPOINTS } = env;

export function setCookie(name, value) {
  const now = new Date();
  now.setTime(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  const expires = 'expires=' + now.toUTCString();
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

export function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}

export function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if the cookie starts with the name we are looking for
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1); // Return the cookie value
    }
  }
  return null; // Return null if the cookie is not found
}

export const signIn = async (email, password) => {
  try {
    const user = await Auth.signIn(email, password);
    return user;
  } catch (error) {
    return false;
  }
};

export const signUpUser = async ({ payload }) => {
  try {
    const res = await axios.post(AWS_API_ENDPOINTS.user.CREATE_NEW_USER, payload);
    return res;
  } catch (e) {
    return { message: e.response.data.message, status: e.response.status };
  }
};

export const handleUserSignIn = async (userData, dispatch, navigate) => {
  try {
    const email = userData?.idToken?.payload?.email || userData?.email;

    const accessToken = await Auth.currentSession()
      .then((session) => {
        return session.getIdToken().getJwtToken();
      })
      .catch((err) => {
        return false;
      });

    if (!accessToken) {
      throw new Error('Token not found');
    }

    dispatch(login(accessToken)); /** Set access token to redux */

    const dataFromDb = await getUserByEmail(email, accessToken);

    if (!dataFromDb) {
      throw new Error('User not found by email');
    }

    dispatch(setProfileData(dataFromDb)); /** Set user to redux */

    // check here if userType is team_member then do if condition
    let shopDetails;

    if (dataFromDb.userDetails.userType === 'team_member') {
      const adminShopRes = await getShopDataForTeamMemberByUserId({
        userId: dataFromDb.userDetails.userId,
        accessToken,
      });

      if (adminShopRes?.status) {
        if (adminShopRes?.status === 'approved') {
          // Now call shop details using shopID then get shop admin user
          const actualShopRes = await getShopDataByShopId({ shopId: adminShopRes.shopId, accessToken });
          shopDetails = actualShopRes;

          dispatch(setShopData(actualShopRes || null)); /** Set shop to Redux */

          const actualShopUserRes = await getUserByEmail(actualShopRes.shopEmail, accessToken);
          if (actualShopUserRes && actualShopUserRes.userDetails) {
            dispatch(setProfileData({ ...dataFromDb, userSubscription: actualShopUserRes.userSubscription }));
          }
        } else {
          dispatch(setShopData(adminShopRes || null)); /** Set shop to Redux */
        }
      } else {
        // check here if user don't have any adminShopRes then it's means it's new user. SO add team memebr using team code
        const teamCodeWithEmail = getCookie('session-team-code');
        if (teamCodeWithEmail && teamCodeWithEmail.split(':')[0] === email) {
          const newUserAddedRes = await postData(`${AWS_API_ENDPOINTS.shop.ADD_TEAM_MEMBER_BY_SHOP_ID}`, accessToken, {
            teamCode: teamCodeWithEmail.split(':')[1],
            teamMemberUserId: dataFromDb.userDetails.userId,
          });

          if (newUserAddedRes && newUserAddedRes.status) {
            shopDetails = newUserAddedRes;
            dispatch(setShopData(newUserAddedRes));
            deleteCookie('session-team-code');
          }
        }

        dispatch(setShopData(null));
      }
    } else {
      let shopDataFromDb = await getShopDataByUserId({
        userId: dataFromDb.userDetails.userId,
        accessToken,
      });

      shopDetails = shopDataFromDb?.[0] || null;

      dispatch(setShopData(shopDataFromDb?.[0] || null)); /** Set shop to Redux */

      // if (!shopDataFromDb?.[0]) {
      //   navigate(ROUTES_PATH.company.details);
      //   return;
      // }

      if (!shopDataFromDb?.[0] && !window.location.pathname.includes(ROUTES_PATH.auth.verify)) {
        navigate(ROUTES_PATH.company.details);
        return;
      }
    }

    // ** Get SHop Address here
    async function getShopAddress(shopId) {
      const url = `${AWS_API_ENDPOINTS.shop.GET_UPDATE_CREATE_SHOP_ADDRESS_BY_SHOP_ID}/${shopId}`;
      const tempData = await getData(url, accessToken);
      if (tempData && tempData.shopAddressId) {
        dispatch(setShopAddress(tempData));
      }
    }

    if (shopDetails && shopDetails.shopId) {
      getShopAddress(shopDetails.shopId);
    }
  } catch (error) {}
};

export const getUserByEmail = async (email, accessToken) => {
  try {
    const response = await getData(`${AWS_API_ENDPOINTS.user.GET_USER_BY_EMAIL}/${email}`, accessToken);
    return response;
  } catch (e) {
    return;
  }
};

export const getShopDataByUserId = async ({ userId, accessToken }) => {
  const res = await getData(`${AWS_API_ENDPOINTS.shop.GET_SHOP_BY_USER_ID}/${userId}`, accessToken);
  return res || false;
};

export const getShopDataByShopId = async ({ shopId, accessToken }) => {
  const res = await getData(`${AWS_API_ENDPOINTS.shop.GET_SHOP_BY_SHOP_ID}/${shopId}`, accessToken);
  return res || false;
};

export const getShopDataForTeamMemberByUserId = async ({ userId, accessToken }) => {
  const res = await getData(`${AWS_API_ENDPOINTS.shop.GET_TEAM_MEMBER_BY_MEMBER_ID}/${userId}`, accessToken);
  return res || false;
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    return await Auth.currentAuthenticatedUser()
      .then(async (user) => {
        return await Auth.changePassword(user, oldPassword, newPassword);
      })
      .catch((err) => {
        return { err: 'Current password provided is incorrect' };
      });
  } catch (error) {
    throw error;
  }
};

export const resendOtp = async ({ phone, email }) => {
  let _phone;
  let _email;

  // ** For Phone Number
  if (phone) {
    if (phone.includes('+91')) {
      _phone = phone;
    } else {
      _phone = `+91${phone}`;
    }
  }

  // ** For Email Address
  if (email) {
    _email = email;
  }

  try {
    const payload = {
      mobileNumber: _phone || '',
      emailAddress: _email || '',
    };

    const response = await postData(AWS_API_ENDPOINTS.user.REQUEST_NEW__OTP, '', payload);

    if (response.status === 'Success') {
      return response.details;
    } else {
      throw new Error('Otp not sent');
    }
  } catch (error) {
    return false;
  }
};

export const verifyOtp = async (mobileNumber, otp, sessionId) => {
  const url = `${COMMON_ENDPOINTS.VERIFY_OTP}`;
  let phone;
  // console.log('mobileNumber::', mobileNumber);

  if (mobileNumber.includes('91') && mobileNumber.length >= 12) {
    phone = `+${mobileNumber}`;
  } else {
    phone = `+91${mobileNumber}`;
  }

  try {
    const response = await axios.post(AWS_API_ENDPOINTS.user.VERIFY_OTP, {
      mobileNumber: phone,
      otp,
      sessionId,
    });

    if (response.data?.status === 'Success') {
      return response.data.details;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const handleForgetPassword = async ({ phoneNumber, emailAddress, otp, newPassword, sessionId }) => {
  try {
    const payload = {
      // ...(phoneNumber ? { phoneNumber: `+ 91${ phoneNumber } ` } : {}),
      // ...(emailAddress ? { emailAddress: emailAddress } : {}),
      mobileNumber: phoneNumber ? `+ 91${phoneNumber} ` : '',
      emailAddress: emailAddress ? emailAddress : '',
      otp,
      password: newPassword,
      sessionId,
    };

    const response = await postData(AWS_API_ENDPOINTS.user.VALIDATE_OTP_AND_CHANGE_PASSWORD, '', payload);

    if (response) {
      return response;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const getPlanList = async () => {
  try {
    const response = await getData(AWS_API_ENDPOINTS.subscription.GET_ALL_PLANS);

    if (response) {
      return response;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const orderSelectedNewPlan = async (payload) => {
  try {
    const response = await postData(AWS_API_ENDPOINTS.subscription.ORDER_SELECTED_NEW_PLAN, '', payload);

    if (response) {
      return response;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};
