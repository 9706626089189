import * as React from 'react';
import { useSelector } from 'react-redux';
// ** MUI Imports
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router';
import { resendOtp } from 'src/utils/auth';

const VerifyNumberAlert = () => {
  // ** Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  // ** Redux States
  const { userInfo } = useSelector((state) => state.authReducer);
  const [loading, setLoading] = React.useState(false);

  async function sendOtp() {
    try {
      setLoading(true);
      const res = await resendOtp({
        phone: userInfo?.userDetails?.mobileNumber,
      });

      if (res) {
        navigate(
          `/auth/verify?user=${userInfo?.userDetails?.mobileNumber.replace('+91', '')}&sessionID=${res}&url=${
            location.pathname
          }`
        );
      } else {
        throw new Error('Otp not send.');
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Otp not send. Try again later!', { variant: 'error' });
    }
  }

  return userInfo && userInfo?.userDetails?.verifyMobile === 'false' ? (
    <>
      <Alert severity="warning" sx={{ mb: 3 }}>
        <AlertTitle>Mobile Number Verification</AlertTitle>
        <Stack spacing={2}>
          <Typography>
            Please confirm your mobile number. Our system will carefully verify it to ensure it meets our standards
            before proceeding.
          </Typography>
          <Button variant="outlined" color="error" sx={{ width: 'fit-content' }} onClick={sendOtp} disabled={loading}>
            {loading ? 'Please wait...' : 'Verify'}
          </Button>
        </Stack>
      </Alert>
    </>
  ) : null;
};

export default VerifyNumberAlert;
